import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Typography,
  FormHelperText,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import axios from "../axios";
// import axiosInstance from "../axios";
import { AuthContext } from "../Context/AuthContext";
import { Helmet } from "react-helmet";
import axios from "axios";
// import axiosInstance from "../axios";

function Login() {
  const {
    loading,
    setLoading,
    email,
    setEmail,
    password,
    setPassword,
    error,
    setError,
    handleuserLogin,
  } = useContext(AuthContext);
  // const [loadingspin, setLoadingspin] = React.useState(false);
  const [searchParams] = useSearchParams()
  let navigate = useNavigate();

  // console.log(searchParams.get("number"))

  useEffect(() => {
    const email = searchParams.get("email")
    // console.log(email)
    const password = searchParams.get("password")
    // console.log(password)
    const number = searchParams.get("number")
    const trader_email = searchParams.get("trader_email")
    console.log(trader_email)
    if(email && password && number){
      axios
      .post("https://maindashbe-june-b18731a0e161.herokuapp.com/api/auth/login/", { email, password })
      .then((res) => {
        setLoading(true);
        //console.log(res, res.data.refresh_token);
        localStorage.setItem("access_token", res.data.access_token);

        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${localStorage.getItem("access_token")}`;
        // axiosInstance.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${localStorage.getItem("access_token")}`;

        localStorage.setItem("refresh_token", res.data.refresh_token);

        navigate("/dashboards?number="+ number);
        //console.log("login success");
      })
      .catch((err) => {
        console.log(err);
        setError("Invalid Email or Password");
      });
    } else if(email && password && trader_email){
      axios
      .post("https://maindashbe-june-b18731a0e161.herokuapp.com/api/auth/login/", { email, password })
      .then((res) => {
        setLoading(true);
        //console.log(res, res.data.refresh_token);
        localStorage.setItem("access_token", res.data.access_token);
        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${localStorage.getItem("access_token")}`;
        // axiosInstance.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${localStorage.getItem("access_token")}`;
        localStorage.setItem("refresh_token", res.data.refresh_token);

        navigate("/affiliate?trader_email="+ trader_email);
        //console.log("login success");
      })
      .catch((err) => {
        console.log(err);
        setError("Invalid Email or Password");
      });
    }
  }, [searchParams, navigate, setError, setLoading])

  return (
    <>
      <Helmet>
        <title>Login - FT9ja</title>
      </Helmet>
      <div className="LoginBox">
        <Box className="LoginForum" component="form" onSubmit={handleuserLogin}>
          <h1>Login</h1>
          <FormControl margin={"normal"}>
            {/* <InputLabel htmlFor="component-outlined">Email</InputLabel> */}
            <TextField
              type={"email"}
              label={"Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name={"email"}
            />
          </FormControl>

          <FormControl margin={"normal"}>
            {/* <InputLabel htmlFor="component-outlined">Password</InputLabel> */}
            <TextField
              type={"password"}
              label={"Password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name={"password"}
            />
          </FormControl>

          <Button
            style={{ marginTop: "15px", marginBottom: "5px", color: "white" }}
            variant="contained"
            type={"submit"}
            onClick={() => setLoading(true)}
            endIcon={
              loading && (
                <CircularProgress
                  size={12}
                  sx={{
                    zIndex: 1,
                    color: "white",
                  }}
                />
              )
            }
          >
            Login
          </Button>
          <FormHelperText id="component-helper-text">
            {error ? error : null}
          </FormHelperText>

          <Typography
            variant="body2"
            sx={{ textAlign: "center" }}
            color="error"
          >
            Dont have an account?{" "}
            <Link className="loginBtn" to="/register">
              Register
            </Link>
          </Typography>
        </Box>
      </div>
    </>
  );
}

export default Login;
