import React from "react";
import axios from "../axios";
import { AccContext } from "../Context/OtherContext";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
function ReLine() {
  const [info, setInfo] = React.useState([]);
  const { values, values5 } = React.useContext(AccContext);
  const [showlogin] = values;
  const [showSca] = values5
  const [lowest, setLowest] = useState(0)
  const [highest, setHighest] = useState(0)
  // const [loading, setLoading] = values8
  // const [setShowType] = values2;
  // const [showsize] = values3;
  // console.log(showsize)

  const getLowest = (data) => {
    const balances = data.map(item => parseFloat(item.balance)).filter(value => !isNaN(value));
    const equities = data.map(item => parseFloat(item.equity)).filter(value => !isNaN(value));

    // Find the lowest and highest balance values
    const lowestBalance = Math.min(...balances);
    const highestBalance = Math.max(...balances);

    // Find the lowest and highest equity values
    const lowestEquity = Math.min(...equities);
    const highestEquity = Math.max(...equities);

    if (lowestBalance < lowestEquity) {
      // console.log('Lowest Value:', Math.round(lowestBalance), ' (Balance)');
      setLowest(Math.round(lowestBalance))
    } else {
      // console.log('Lowest Value:', Math.round(lowestEquity), ' (Equity)');
      setLowest(Math.round(lowestEquity))
    }

    if (highestBalance > highestEquity) {
      // console.log('Highest Value:', Math.round(highestBalance), ' (Balance)');
      setHighest(Math.round(highestBalance))
    } else {
      // console.log('Highest Value:', Math.round(highestEquity), ' (Equity)');
      setHighest(highestEquity)
    }
  }


  React.useEffect(() => {
    getEquAndBalAndTime();
    let interval = setInterval(() => {
      getEquAndBalAndTime();
    }, 294000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showlogin, showSca]);

  let getEquAndBalAndTime = async () => {
    if (showSca !== "") {
      await axios
        .get(`/dash/trades/?account=${showSca}`)
        .then((res) => {
          // console.log(res.data);
          if (res.data.length > 0) {
            setInfo(res.data);
            getLowest(res.data)
          } else {
            setInfo([]);
          }
        })
        .catch((err) => {
          //console.log(err);
          setInfo([]);
        });
    } else {
      await axios
        .get(`/dash/trades/?account=${showlogin}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) {
            setInfo(res.data);
            getLowest(res.data)
          } else {
            setInfo([]);
          }
        })
        .catch((err) => {
          //console.log(err);
          setInfo([]);
        });
    }

  };
  // let getEquAndBalAndTime = async () => {
  //   await axios
  //     .post("/geteqbal/", { number: showlogin })
  //     .then((res) => {
  //       //console.log(res.data);
  //       if (res.data.length > 0) {
  //         setInfo(res.data);
  //       } else {
  //         setInfo([]);
  //       }
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //       setInfo([]);
  //     });
  // };

  const formatXAxis = (tickItem) => {
    if (tickItem) {
      let tickItemss = tickItem.split(" ");
      return tickItemss[0].toString();
    } else {
      return "";
    }
  };

  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active) {
  //     if (payload) {
  //       return (
  //         <div className="custom-tooltip">
  //           <p
  //             className="label"
  //             style={{ color: "black" }}
  //           >{`date : ${payload[0]?.payload.date}`}</p>
  //           <p
  //             className="label"
  //             style={{ color: "#F77E27B2" }}
  //           >{`${payload[0]?.name} : ${payload[0]?.value}`}</p>
  //           <p
  //             className="label"
  //             style={{ color: "#1D435380" }}
  //           >{`${payload[1]?.name} : ${payload[1]?.value}`}</p>
  //         </div>
  //       );
  //     }
  //     return " ";
  //   }
  //   return " ";
  // };
  return (
    <ResponsiveContainer width={"100%"} height={317}>
      <AreaChart
        data={info}
        margin={{ top: 50, right: 40, left: 0, bottom: 20 }}
      >
        <CartesianGrid vertical={false} />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#F77E27B2" stopOpacity={0.7} />
            <stop offset="95%" stopColor="#D9D9D900" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1D435380" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#D9D9D900" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={formatXAxis}
          angle={31}
          minTickGap={-20}
          // axisLine={false}
          dx={35}
          dy={10}
          tickLine={false}
          tick={{ fontSize: 11 }}
          label={{
            value: "Date",
            offset: -19,
            position: "insideBottomRight",
          }}
        />
        <YAxis
          type="number"
          // domain={[dataMin => (0 - Math.abs(dataMin)), dataMax => (dataMax * 2)]}
          domain={[dataMin => {
            // console.log(dataMin)
            return lowest - 100
          }, dataMax => {
            // console.log(dataMax)
            return highest + 100
          }]}
          tickLine={true}
          tickFormatter={(tickItem) => {
            if (tickItem) {
              let newtick = Math.round(tickItem);
              return newtick;
            } else {
              return 0;
            }
          }}
          values=""
          tick={{ fontSize: 11 }}
          label={{
            value: "Equity/Balance",
            angle: -90,
            position: "insideLeft",
            fontSize: 15,
          }}
        />
        <Tooltip />
        {/* <Legend /> */}
        <Area
          type="monotone"
          dataKey="equity"
          stroke="#F77E27B2"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey="balance"
          stroke="#1D435380"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default ReLine;
