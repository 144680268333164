import React, { Fragment, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import img from "../images/logo.png";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Menu, Transition } from "@headlessui/react";
import { AccContext } from "../Context/OtherContext";
import axios from "../axios";
import NotifModal from "./NotifModal";

function MainNav() {
  const mobile = useMediaQuery("(max-width:600px)");
  const [nav, setNav] = React.useState(false);
  const location = useLocation();
  const [available, setAvailable] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handlelogout = () => {
    localStorage.setItem("access_token", "");
    localStorage.setItem("refresh_token", "");
    window.location.href = "https://ft9ja.com/login";
  };

  useEffect(() => {
    if (nav === true) {
      document.body.style.overflow = "hidden";
    }
    if (nav === false) {
      document.body.style.overflow = "";
    }
  }, [nav]);

  const { values9 } = React.useContext(AccContext);
  // eslint-disable-next-line no-unused-vars
  const [globalRefresh, setGlobalRefresh] = values9;

  useEffect(() => {
    const intervalId = setInterval(
      axios
        .get("/notifications/")
        .then((res) => {
          console.log(res);
          if (res.data.length > 0) {
            setAvailable(true);
          } else {
            setAvailable(false);
          }
        })
        .catch((err) => {
          console.log(err);
        }),
      120000
    );

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => {
      clearInterval(intervalId);
    };
  }, [globalRefresh]);

  const [searchParams] = useSearchParams();
  const number = searchParams.get("number");
  const trader_email = searchParams.get("trader_email");
  console.log(trader_email);

  // const desktop = {
  //   fontWeight: "494",
  //   borderRadius: "5px",
  //   minHeight: "0px",
  //   m: 1,
  //   backgroundColor: "#FEFEFE",
  //   textTransform: "none",
  //   color: " rgba(0, 0, 0, 0.8)",
  //   "&.Mui-selected": {
  //     backgroundColor: "#359602",
  //     color: "white",
  //   },
  // };
  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;
  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ textAlign: "-webkit-center" }}>{children}</Box>
  //       )}
  //     </div>
  //   );
  // }
  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`,
  //   };
  // }
  return (
    <>
      <div className="addPadding">
        {mobile ? (
          <Box className="MobileContainer">
            <Link className="LogoImage" to="/dashboards">
              <img
                src={img}
                alt={""}
                style={{ width: "95px", height: "60px" }}
              />
            </Link>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <div
                onClick={() => setOpen(!open)}
                className={`Tabz1 cursor-pointer relative`}
              >
                {available && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 4 4"
                    fill="none"
                    className="absolute top-[0.6rem] right-[1rem]"
                  >
                    <circle cx="2" cy="2" r="2" fill="#FF0000" />
                  </svg>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z"
                    fill="black"
                    fill-opacity="0.8"
                  />
                  <path
                    d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
                    fill="black"
                    fill-opacity="0.8"
                  />
                </svg>
              </div>
              {nav ? (
                <Button onClick={() => setNav(false)}>
                  <CloseIcon sx={{ color: "#359602", fontSize: "40px" }} />
                </Button>
              ) : (
                <Button onClick={() => setNav(true)}>
                  <MenuIcon sx={{ color: "#359602", fontSize: "40px" }} />
                </Button>
              )}
            </div>
          </Box>
        ) : (
          <Box className="NavContainer">
            <Link className="LogoImage" to="/dashboards">
              <img src={img} alt={""} className="NavImage" />
            </Link>

            <Box className="TabGrp1">
              {number === null ? (
                <Link
                  to="/dashboards"
                  className={`Tab1 ${
                    location.pathname === "/dashboards" && "NavActive"
                  }`}
                >
                  Dashboard
                </Link>
              ) : (
                <Link
                  to={"/dashboards?number=" + number}
                  className={`Tab1 ${
                    location.pathname === "/dashboards" && "NavActive"
                  }`}
                >
                  Dashboard
                </Link>
              )}
              {number === null ? (
                <Link
                  to="/affiliate"
                  className={`Tab01 ${
                    location.pathname === "/affiliate" && "NavActive"
                  }`}
                >
                  Affiliate
                </Link>
              ) : (
                <Link
                  to={"/affiliate?number=" + number}
                  className={`Tab01 ${
                    location.pathname === "/affiliate" && "NavActive"
                  }`}
                >
                  Affiliate
                </Link>
              )}
              {number === null && trader_email === null && (
                <Link
                  to="/apps"
                  className={`Tab1 ${
                    location.pathname === "/apps" && "NavActive"
                  }`}
                >
                  Apps
                </Link>
              )}
              {number === null && trader_email === null && (
                <Link
                  // to={`https://ft9ja-selfservice.netlify.app/selfservice?token=${localStorage.getItem(
                  //   "access_token"
                  // )}&refresh_token=${localStorage.getItem("refresh_token")}`}
                  onClick={() =>
                    window.open(
                      `http://selfservice.ft9ja.com/selfservice?token=${localStorage.getItem(
                        "access_token"
                      )}&refresh_token=${localStorage.getItem(
                        "refresh_token"
                      )}`,
                      "_blank"
                    )
                  }
                  className={`Tab1 ${
                    location.pathname === "/self" && "NavActive"
                  }`}
                >
                  Self Service
                </Link>
              )}
              {number === null && trader_email === null && (
                <Menu as="div">
                  <Menu.Button
                    className={`Tab01 relative border-none bg-gray-50 inline-block text-left hover:bg-[#359602] ${
                      location.pathname === "/profile" && "NavActive"
                    }`}
                  >
                    Profile
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-7 border z-10 mt-3 w-[120px] origin-top-right divide-y divide-gray-300 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col gap-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/profile">
                            <button
                              className={`${
                                active
                                  ? "bg-[#359602] text-white font-poppins font-medium"
                                  : "text-gray-900 bg-white"
                              } group flex w-full items-center  font-poppins outline-none border-none font-medium px-2 py-3 text-base`}
                            >
                              Your Profile
                            </button>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handlelogout}
                            className={`${
                              active
                                ? "bg-[#359602] text-white font-poppins font-medium"
                                : "text-gray-900 bg-white"
                            } group flex w-full items-center font-poppins font-medium border-none px-2 py-3 text-base`}
                          >
                            Logout
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
              {number === null && trader_email === null && (
                <div
                  onClick={() => setOpen(!open)}
                  className={`Tabz1 cursor-pointer relative`}
                >
                  {available && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="7"
                      viewBox="0 0 4 4"
                      fill="none"
                      className="absolute top-[0.6rem] right-[1rem]"
                    >
                      <circle cx="2" cy="2" r="2" fill="#FF0000" />
                    </svg>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z"
                      fill="black"
                      fill-opacity="0.8"
                    />
                    <path
                      d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
                      fill="black"
                      fill-opacity="0.8"
                    />
                  </svg>
                </div>
              )}
            </Box>
          </Box>
        )}
        <NotifModal isOpen={open} setIsOpen={setOpen} />
      </div>
      {nav && (
        <>
          <div className="bgTransparent"></div>
          <Box className="MobileMenu">
            <Box className="MobileMenuItems">
              <Link
                onClick={() => setNav(false)}
                className={`MMItem ${
                  location.pathname === "/dashboards" && "MMItemActive"
                }`}
                to="/dashboards"
              >
                Dashboard
              </Link>
              <Link
                onClick={() => setNav(false)}
                className={`MMItem1 ${
                  location.pathname === "/affiliate" && "MMItemActive"
                }`}
                to="/affiliate"
              >
                Affiliate
              </Link>
              <Link
                onClick={() => setNav(false)}
                className={`MMItem ${
                  location.pathname === "/apps" && "MMItemActive"
                }`}
                to="/apps"
              >
                Apps
              </Link>
              <Link
                // onClick={() => setNav(false)}
                onClick={() =>
                  window.open(
                    `http://selfservice.ft9ja.com/selfservice?token=${localStorage.getItem(
                      "access_token"
                    )}&refresh_token=${localStorage.getItem("refresh_token")}`,
                    "_blank"
                  )
                }
                className={`MMItem ${
                  location.pathname === "/self" && "MMItemActive"
                }`}
                // to={`https://ft9ja-selfservice.netlify.app/selfservice?token=${localStorage.getItem(
                //   "access_token"
                // )}&refresh_token=${localStorage.getItem("refresh_token")}`}
              >
                Self Service
              </Link>
              <Link
                onClick={() => setNav(false)}
                className={`MMItem1 ${
                  location.pathname === "/profile" && "MMItemActive"
                }`}
                to="/profile"
              >
                Profile
              </Link>
              <Link
                onClick={() => {
                  setNav(false);
                  handlelogout();
                }}
                className={`MMItem1`}
                // to="/profile"
              >
                Logout
              </Link>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default MainNav;
