
import React from 'react'
import { AccContext } from "../Context/OtherContext";
import {
    Box,
    Modal,
    Typography,
} from "@mui/material";

import axios from "../axios";
import { ToastContainer, toast } from "react-toastify";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const mainContainer = {
    justifyContent: "center",
    alignItems: "center",
};



function RefPayout() {
    const [open, setOpen] = React.useState(false)
    const [accNum, setAccNum] = React.useState("");
    const [bankName, setBankName] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [loading, setLoading] = React.useState(false)
    const { values6 } = React.useContext(AccContext);
    const [exists, setExists] = React.useState([])
    const [disable, setDisable] = React.useState(false)
    const [banked] = values6
    const existsReverse = exists.reverse()
    const [refresh, setRefresh] = React.useState(false)
    console.log(banked)

    const showRequestSuccess = (message) => {
        toast.success(message, {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    };

    const showRequestFailed = (message) => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    };

    React.useEffect(() => {
        axios.get("/profile/").then((res) => {
            //console.log(res.data);
            setFirstname(res.data.user.firstname);
            setLastname(res.data.user.lastname);
        });
    }, []);

    React.useEffect(() => {
        axios.get("/update-bank-details/").then((res) => {
            // console.log(res.data);
            if (res.data.account_number !== null && res.data.bank_name !== null) {
                setAccNum(res.data.account_number);
                setBankName(res.data.bank_name);

            } else {
                setAccNum("");
                setBankName("");

            }

        });
    }, []);

    const sendToBackend = () => {
        axios.post(`refgraph/?amount=${banked}`).then(res => {
            // console.log(res)
            setRefresh(!refresh)
            showRequestSuccess("Referral payout request has been sent.")
            setLoading(false)
            // window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }


    React.useEffect(() => {
        const getData = async () => {
            try {
                const res = await fetch("https://sheetdb.io/api/v1/lx1qt8sgjm0gl");
                const data = await res.json();
                // console.log(data)
                setExists(data.filter(res => Number(res.Amount_Naira) === banked && res.Account_Number === accNum))
                setDisable(false)
            } catch (error) { }
        };
        getData()
    }, [accNum, banked, refresh])

    const handleRefPayout = () => {
        setLoading(true)
        const date = new Date()
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        const readableDate = date.toLocaleDateString('en-US', options);
        if (banked > 0) {
            if (existsReverse[0]?.Date === readableDate) {
                setRefresh(!refresh)
                showRequestFailed("Payout request has already been made")
                // window.location.reload()
                setLoading(false)
            } else {

                fetch('https://sheetdb.io/api/v1/lx1qt8sgjm0gl', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: [
                            {
                                'id': "INCREMENT",
                                'Account_Name': lastname + " " + firstname,
                                'Account_Number': accNum,
                                'Amount_Naira': banked,
                                'Approval_Status': "No",
                                'Bank': bankName,
                                'Completed': "No",
                                'Date': readableDate,
                                'Type': "FT9ja referral commission"

                            }
                        ]
                    })
                })
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log(data)
                        setDisable(true)
                        setRefresh(!refresh)
                        sendToBackend()
                    }).catch(err => {
                        console.log(err)
                        setLoading(false)
                        showRequestFailed("Unable to sent payout request")
                    })
            }

        } else {
            setOpen(true)
        }
    }
    return (
        <div className='flex flex-col items-center mt-10'>
            <ToastContainer />
            <p className='text-center text-base'>Click the button below to request for referral commisson payout</p>
            <button disabled={loading || disable} onClick={() => handleRefPayout()} className='cursor-pointer text-white bg-[#359602] h-[48px] border-none text-base rounded-lg px-3'>{loading ? "Sending..." : "Referral Payout"}</button>
            <p>Referral Commission: #{banked}</p>
            <Modal open={open} onClose={() => {
                setOpen(false)
                setLoading(false)
            }}>
                <Box sx={style}>
                    <Box sx={mainContainer}>
                        <Typography>
                            Notice: You do not have a referral commission.
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default RefPayout