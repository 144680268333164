/* eslint-disable no-unused-vars */
import React from "react";
import { Box } from "@mui/material";
import { AccContext } from "../Context/OtherContext";
import axios from "../axios";
import { useSearchParams } from "react-router-dom";
function AccountDetails() {
  const [info, setInfo] = React.useState([]);
  const { values, values2, values5, values7, values10 } = React.useContext(AccContext);
  const [showlogin, setShowlogin] = values;

  // eslint-disable-next-line no-unused-vars
  const [showtype, setShowType] = values2;
  const [showSca] = values5;
  const [showBlown, setShowBlown] = values7;
  const [showInactive, setShowInactive] = values10;
  console.log(showBlown);
  console.log(showInactive);


  const [searchParams] = useSearchParams();

  const number = searchParams.get("number");
  React.useEffect(() => {
    // console.log(number)
    if (showlogin !== "" && showSca !== "") {
      console.log(showSca);
      axios
        .get(`/getaccdetailsbynum/?number=${showlogin}&sca=${showSca}`)
        .then((res) => {
          console.log(res.data);
          setInfo(res.data[0]);
          //console.log(res.data[0].acc.typeofaccount, setShowType);
          setShowType(res.data[0].typeofaccount);
          setShowBlown(res.data[0].blown);
          setShowInactive(res.data[0].inactive);

        })
        .catch((err) => {
          console.log(err);
        });
    } else {

      axios
        .get("/getaccdetails/")
        .then((res) => {
          console.log(res);
          // setInfo(res.data[0].acc);
          //   setShowlogin(res.data[0].acc.number);
          //   setShowType(res.data[0].acc.typeofaccount);
          if (res.data.length === 1) {
            setInfo(res.data[0].acc);
            setShowlogin(res.data[0].acc.number);
            setShowType(res.data[0].acc.typeofaccount);
          } else if (number !== null) {
            setShowlogin(number);
            axios
              .get(`/support/getaccdetailsbynum/?number=${number}`)
              .then((res) => {
                console.log(res);
                if (!res.data.message) {
                  if (res.data.trader) {
                    setInfo(res.data);
                    setShowType(res.data.typeofaccount);
                  } else {
                    setInfo(res.data.acc);
                    setShowType(res.data.acc.typeofaccount);
                  }
                }else{
                  alert("Invalid account number")
                }
                //console.log(res.data[0].acc.typeofaccount, setShowType);
              });
          } else if (showlogin !== "" && showSca !== "") {
            console.log(showSca);
            axios
              .get(`/getaccdetailsbynum/?number=${showlogin}&sca=${showSca}`)
              .then((res) => {
                console.log(res.data);
                setInfo(res.data[0]);
                //console.log(res.data[0].acc.typeofaccount, setShowType);
                setShowType(res.data[0].typeofaccount);
                setShowBlown(res.data[0].blown);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            axios
              .get(`/getaccdetailsbynum/?number=${showlogin}`)
              .then((res) => {
                console.log(res);
                setInfo(res.data[0].acc);
                //console.log(res.data[0].acc.typeofaccount, setShowType);
                setShowBlown(res.data[0].blown);
                setShowType(res.data[0].acc.typeofaccount);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showlogin, showSca]);
  return (
    <Box className="Accdetails">
      <Box className="AccHead">Account Details</Box>

      <p className="AccText">
        {number === null && (
          <span style={{ fontWeight: "bold" }}>Platform: </span>
        )}
        {number === null && (
          <span style={{ fontWeight: "375" }}> {info.broker}</span>
        )}
        <br />
        <span style={{ fontWeight: "bold" }}>Login: </span>
        <span style={{ fontWeight: "375" }}> {info.number}</span>
        <br />
        {number === null && (
          <span style={{ fontWeight: "bold" }}>Password: </span>
        )}
        {number === null && (
          <span style={{ fontWeight: "375" }}> {info.password}</span>
        )}
        <br />
        {number === null && (
          <span style={{ fontWeight: "bold" }}>Server: </span>
        )}
        {number === null && (
          <span style={{ fontWeight: "375" }}> {info.platform}</span>
        )}
        <br />

        <span style={{ fontWeight: "bold" }}>Account Size: </span>

        <span style={{ fontWeight: "375" }}> {info.acc_size}</span>

        <br />
        {number === null && (
          <span style={{ fontWeight: "bold" }}>Investor Password: </span>
        )}
        {number === null && (
          <span style={{ fontWeight: "375" }}> {info.investor_password}</span>
        )}

        <br />
        {info.profit_target && (
          <span style={{ fontWeight: "bold" }}>Profit Target+: </span>
        )}
        {info.profit_target && (
          <span style={{ fontWeight: "375" }}> {info.profit_target}</span>
        )}
        <br />
        {info.minimum_balance && (
          <span style={{ fontWeight: "bold" }}>Account Drawdown+: </span>
        )}
        {info.minimum_balance && (
          <span style={{ fontWeight: "375" }}> {info.minimum_balance}</span>
        )}
        <br />
        {info.ddx !== null && (
          <span style={{ fontWeight: "bold" }}>Daily Drawdown+: </span>
        )}
        {info.ddx && <span style={{ fontWeight: "375" }}> {info.ddx}</span>}
      </p>
    </Box>
  );
}

export default AccountDetails;
