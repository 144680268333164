import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography, Grid } from "@material-ui/core";
import { Button } from "@mui/material";
import BankTransfer from "../../Assets/BankTransfer.png";
import Paystack from "../../Assets/Paystack.png";
import Paypal from "../../Assets/Paypal.png";
import Cancel from "../../Assets/cancel.png";
import { v4 as uuidv4 } from 'uuid';

import { usePaystackPayment } from "react-paystack";
// import Modal from "@mui/material/Modal";


import { BankTransferModal, PayPalModal } from "./";
import axios from "../../axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    padding: theme.spacing(4),
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    // position: "relative",
    // bottom: theme.spacing(1),
    // left: theme.spacing(55),
    backgroundColor: "#D9D9D9",
    width: 29,
    height: 29,
    marginLeft:"auto"
  },
  cancelIcon: {
    width: 10,
    height: 10,
  },
  options: {
    flexGrow: 1,
    // marginTop: 20,
    marginTop: 40,
    width: "100%",
    padding: 20,
  },
  button: {
    boxShadow:
      "0px 1px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0), 0px 1px 10px 0px rgba(0,0,0,0)",
    "&:hover": {
      transform: "scale(1.1)",
    },
    backgroundColor: "white",
    height: 150,
    width: "100%",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "30%",
      margin: 10,
    },
  },
}));

const paymentMethods = [
  { id: "paystack", icon: Paystack },
  { id: "paypal", icon: Paypal },
  { id: "bank", icon: BankTransfer },
];

const message = {
  title: "Fund your account",
  body: "Kindly note that not all payment options may be accessible depending on your location.",
};

// const onSuccess = (reference) => {
//   // console.log(reference);
//   axios.post("/paystack-receipt/", reference).then(res => {
//     // console.log(res)
//     makeAccount()
    
//   }).catch(err => {
//     console.log(err)
//   })
// };

const onClose = () => {
  //console.log("closed");
};

const PaymentOptions = ({ email, userAccountId, discount, amount, dCode, closeOptions, onPaymentCompleted, payout, broker, acctype, size, changeLoading }) => {
  const [openBankModal, setOpenBankModal] = useState(false);
  const [openPayPalModal, setOpenPayPalModal] = useState(false);

  const onSuccess = (reference) => {
    // console.log(reference);
    axios.post("/paystack-receipt/", {...reference, discount_code:dCode}).then(res => {
      // console.log(res)
      makeAccount()
      changeLoading()
      
    }).catch(err => {
      console.log(err)
    })
  };

  const makeAccount = () => {
    //console.log(size, broker, payout);
    // setLoading(true);
    // setShowPaymentOptions(true);
    let data = dCode === "" ?  {
      acc_size: size,
      broker: broker,
      payout: payout,
      typeofaccount: acctype
    } : {
      acc_size: size,
      broker: broker,
      payout: payout,
      typeofaccount: acctype,
      discount_code:dCode
    }
    axios
      .post("/getnewacc/", data)
      .then((res) => {
        console.log(res);
        window.location.href = "https://dashboard.ft9ja.com/payment-successful"
        // showUploadSuccess(res.data.message);
        // window.location.href = "https://dashboard.ft9ja.com/payment-successful"
        // setIsUploading(false)
        // setLoading(false);
        // setShowPaymentOptions(true);
      });
  };

  const classes = useStyles();

  //--Paystack const--//
  const publicKey = "pk_live_45cb4ea56402832c4859a716fafa44439ef2c6a5";
  // const publicKey = "pk_test_4f31c1621f7d6c77370c320e0b860779f763e415";
  const reference = uuidv4();
  let discounta = (100 - discount) / 100
  let paystack_amount = amount * discounta
  console.log(paystack_amount)
  let bank_amount = (amount / 100) * discounta ;
  const config = { reference, email, amount:paystack_amount, publicKey };

  const initializePayment = usePaystackPayment(config);

  // let usd_amount = Math.trunc(bank_amount/960);
  let new_usd_amount = getPaypalPrice()
  function getPaypalPrice() {
    if(window.location.pathname === "/classic"){
      if(size === "3000"){
        return 42 * discounta
      }else if(size === "5000"){
        return 64 * discounta
      } else if(size === "1000") {
        return 15 * discounta
      }else{
        return 257 * discounta
      }
    }else{
      if(size === "5000"){
        return 41 * discounta
      }else if(size === "10000"){
        return 70 * discounta
      } else if(size === "1000") {
        return 9 * discounta
      }else{
        return 141 * discounta
      }
    }
  }
  
  console.log(new_usd_amount)


  const handlePaymentMethod = (item) => {
    //console.log(item);
    if (item.id === "paystack") initializePayment(onSuccess, onClose);
    if (item.id === "bank") setOpenBankModal(true);
    if (item.id === "paypal") setOpenPayPalModal(true);
  };

  return (
    <div className={classes.root}>
      <Button
        onClick={() => closeOptions(false)}
        className={classes.cancelButton}
      >
        <img src={Cancel} alt={""} className={classes.cancelIcon} />
      </Button>
      <Typography variant="h3" className={classes.title}>
        {message.title}
      </Typography>
      <Divider />
      <Typography>{message.body}</Typography>

      <div className={classes.options}>
        <Grid container className={classes.gridContainer}>
          {paymentMethods.map((item, index) => (
            <Grid item key={index} className={classes.gridItem}>
              <Button
                onClick={() => handlePaymentMethod(item)}
                className={classes.button}
              >
                <img src={item.icon} alt={""}/>
              </Button>
            </Grid>
          ))}
        </Grid>
        <BankTransferModal
          open={openBankModal}
          handleClose={() => setOpenBankModal(false)}
          amount={bank_amount}
          userAccountId={userAccountId}
          onReceiptUploaded={onPaymentCompleted}
          payout={payout}
          broker={broker}
          size={size}
          changeLoading={changeLoading}
          type={acctype}
          dCode={dCode}
        />

        <PayPalModal
          open={openPayPalModal}
          handleClose={() => setOpenPayPalModal(false)}
          amount={new_usd_amount}
          payout={payout}
          broker={broker}
          size={size}
          changeLoading={changeLoading}
          type={acctype}
          dCode={dCode}
        />
      </div>

    </div>
  );
};

export default PaymentOptions;
