import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import LandingCarousel from "../Componets/LandingCarousel";
import LandingTable from "../Componets/LandingTable";
import axios from "../axios";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import Cookies from 'js-cookie';
function Home() {
  const token = localStorage.getItem("access_token");
  const [info, setInfo] = React.useState({});
  const refresh_token = localStorage.getItem("access_token");
  const [accounts, setAccounts] = useState(false);
  React.useEffect(() => {
    axios
      .get(`/auth/user/`)
      .then((res) => {
        console.log(res.data.is_staff);
        setInfo(res.data.is_staff);
      })
      .catch((err) => {
        console.log(err);
      });
    // localStorage.setItem("access_token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzExODA2ODY5LCJpYXQiOjE3MDkyMTQ4NjksImp0aSI6ImQyOGU1OTQ5Y2QxYjQ4YmM5Zjc5NGMxNjNlODA5YjZmIiwidXNlcl9pZCI6Mzh9.3e9i1SRSHvEmET2R8-3vGSvw_byKHTsfPIK48Z_mnyw")
    // localStorage.setItem("refresh_token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcxMjY3MDg2OSwiaWF0IjoxNzA5MjE0ODY5LCJqdGkiOiJkZDZiYzYzMGM3YWQ0MDM2OGRkNTBjYjA1Yjk3MTBhZiIsInVzZXJfaWQiOjM4fQ.SjrbfNNy2LUe1kVU--m1GfR3Z6F8MkqonwnhFkoluDA")
  }, []);

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  // const number = searchParams.get("number")
  React.useEffect(() => {
    // Cookies.set("access_token", null);
    // Cookies.set("refresh_token", null);
    var token = searchParams.get("token");
    var refresh = searchParams.get("refresh_token");
    if (token) {
      // Cookies.set("access_token", token)
      localStorage.setItem("access_token", token);
    }

    if (refresh) {
      // Cookies.set("refresh_token", refresh)
      localStorage.setItem("refresh_token", refresh);
    }

    if (token !== null) {
      //console.log("token is not null");
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    axios
      .get("/getaccdetails/")
      .then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          setAccounts(true);
        } else {
          setAccounts(false);
        }
        // window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        setAccounts(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const hasReloaded = sessionStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Home - FT9ja</title>
      </Helmet>
      <Box>
        <Box className="Landing">
          <Box
            sx={{
              paddingY: 2,
              paddingLeft: 1,
            }}
          >
            {/* {token ? (
              <Button
                className="Btn"
                sx={{
                  position: "absolute",
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/dashboards"
              >
                Dashboard
              </Button>
            ) : (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/login"
              >
                Login
              </Button>
            )} */}
            {!token && (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/login"
              >
                Login
              </Button>
            )}
            {accounts && (
              <Link to="/dashboards">
                <Button
                  className="Btn"
                  sx={{
                    position: "absolute",
                    float: "right",
                    fontSize: 18,
                    textTransform: "none",
                    background: "#359602",
                    " &:hover": {
                      backgroundColor: "#359602",
                    },
                  }}
                  variant="contained"
                  // href="/dashboards"
                >
                  Dashboard
                </Button>
              </Link>
            )}
            {info === true ? (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href={`https://www.admin.ft9ja.com/?token=${token}&refresh_token=${refresh_token}`}
              >
                Admin
              </Button>
            ) : null}
          </Box>
          <Box
            sx={{
              fontSize: 15,
            }}
            className="Landingheader"
          >
            <h1>Trade up to $50,000</h1>
            <p className="landp">
              Get the Account Size required to become a successful Trader
            </p>
            <Button
              className="landbtn"
              sx={{
                fontSize: 20,
                textTransform: "none",
                background: "#359602",
                " &:hover": {
                  backgroundColor: "#359602",
                },
              }}
              variant="contained"
              href="#table"
            >
              Let’s start
            </Button>
          </Box>
          <br />
          <LandingCarousel />
          <br />
          <Box className="Landingheader">
            {/* <h6>
              Thousands of Traders love trading with FT9ja Prop Firm. Here are
              some of their stories.
            </h6> */}
            <div id="table" className="">
              <LandingTable />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Home;
