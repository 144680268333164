import React from "react";
import { Box, Button, CircularProgress, Snackbar } from "@mui/material";
import axios from "../axios";
import { AccContext } from "../Context/OtherContext";
// import { AccContext } from "../Context/OtherContext";
function PayoutLink() {
  const { values, values2, values5, values7, values10 } =
    React.useContext(AccContext);
  const [showlogin] = values;
  const [showtype] = values2;
  const [showSca] = values5;
  const [showBlown] = values7;
  const [showInactive] = values10;
  // let showInactive = true
  const [disable, setdisable] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  console.log(showtype);
  const handleClick = () => {
    setLoading(true);
    if (showSca) {
      axios.post("/payout/", { number: showSca }).then((res) => {
        console.log(res);
        setdisable(true);
        setLoading(false);
        setOpen(true);
      });
    } else {
      axios.post("/payout/", { number: showlogin }).then((res) => {
        // console.log(res);
        setdisable(true);
        setLoading(false);
        setOpen(true);
      });
    }
  };
  React.useEffect(() => {
    var currentDate = new Date();

    // Get the day of the week (0-6)
    var dayOfWeek = currentDate.getDay();
    // console.log(dayOfWeek)
    if (showSca) {
      console.log("sca called");
      axios
        .get(`/check-sca-payoutshow/?number=${showSca}`)
        .then((res) => {
          console.log(res);
          if (res.data === true) {
            if (dayOfWeek !== 0 || dayOfWeek !== 6) {
              setdisable(false);
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      console.log("login called");
      axios
        .get(`/checkpayoutshow/?number=${showlogin}`)
        .then((res) => {
          console.log(res.data);
          if (res.data === true) {
            if (dayOfWeek !== 0 || dayOfWeek !== 6) {
              setdisable(false);
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [showSca, showlogin]);
  return (
    <div className="PayoutSection2">

      {(showtype.toLowerCase() === "zuma" || showtype.toLowerCase() === "zuma+" || showtype.toLowerCase() === "verification"  || showtype.toLowerCase() === "challenge" || showtype.toLowerCase() === "aso+" || showtype.toLowerCase() === "aso" || showtype.toLowerCase() === "zuma-second-chance") && showBlown === false ?(

        <Button
          disabled={disable}
          onClick={handleClick}
          variant="outlined"
          className="Payoutbtn"
          sx={{
            color: "white",
            cursor: "pointer",
            backgroundColor: "#359602",
            "&:hover": {
              backgroundColor: "#359602",
              cursor: "pointer",
            },
          }}
          endIcon={
            loading && (
              <CircularProgress
                size={12}
                sx={{
                  zIndex: 1,
                  color: "white",
                }}
              />
            )
          }
        >
          Request Payout
        </Button>
      ) : showBlown === true ? (
        <div>
          This account is blown and so is not eligible to make payout requests
        </div>
      ) : showInactive ? (
        <div>
          This account has been inactive for more than 2 weeks and as such is
          not eligible for payout
        </div>
      ) : (
        <div>This account type is not eligible to make payout requests</div>
      )}
      <Snackbar
        message="Request Sent"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={20000}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Box className="PayoutBoxlink">
        <ol>
          <li> Payouts are processed on Fridays. </li>
          <li>
            {" "}
            Only request made before 12 noon WAT on Friday will be processed
            that week.{" "}
          </li>
          <li>
            {" "}
            Payouts will be sent within 24 working hours (after 12 noon WAT on
            Friday).{" "}
          </li>
          <li> Remember to close all trades before requesting for payouts. </li>
          <li>
            Ensure that your bank information on the profile page has been
            updated before requesting for payout.
          </li>
        </ol>
      </Box>
    </div>
  );
}

export default PayoutLink;
