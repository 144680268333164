import { Button, Typography, Modal, Box } from "@mui/material";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../axios";
import { AccContext } from "../Context/OtherContext";
import { useEffect } from "react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const mainContainer = {
    justifyContent: "center",
    alignItems: "center",
};

function AsoVerificationRequest() {
    const [open, setOpen] = useState(false)
    const [eligible, setEligible] = useState(false)
    const { values, values5, values2 } = React.useContext(AccContext);
    const [checkRequest, setCheckRequest] = useState({
        blown: true,
        pending_request: true,
        profit: true,
        trading_days: true,
        weeks_passed: true
    })
    const [showlogin] = values;
    const [showSca] = values5
    const [showtype] = values2
    //--Toast messages--//
    const showRequestSuccess = (message) => {
        toast.success(message, {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    };

    const showRequestFailed = (message) => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    };

    useEffect(() => {
        const confirmVerification = async () => {
            axios.get("confirm-verification-request/?number=" + showlogin).then(res => {
                console.log(res)
                if (res.data.all_conditions_met) {
                    setEligible(true)
                } else {
                    setEligible(false)
                    setCheckRequest({
                        blown: res.data.blown,
                        pending_request: res.data.pending_request,
                        profit: res.data.profit,
                        trading_days: res.data.trading_days,
                        weeks_passed: res.data.weeks_passed
                    })
                }
            }).catch(err => {
                console.log(err)
                setEligible(false)
            })
        }

        const confirmAso = async () => {
            if (showSca !== "") {
                axios.get("confirm-aso-request/?number=" + showSca).then(res => {
                    console.log(res)
                    if (res.data.all_conditions_met) {
                        setEligible(true)
                    } else {
                        setEligible(false)
                        setCheckRequest({
                            blown: res.data.blown,
                            pending_request: res.data.pending_request,
                            profit: res.data.profit,
                            trading_days: res.data.trading_days,
                            weeks_passed: true
                        })
                    }
                }).catch(err => {
                    console.log(err)
                    setEligible(false)
                })
            } else {
                axios.get("confirm-aso-request/?number=" + showlogin).then(res => {
                    console.log(res)
                    if (res.data.all_conditions_met) {
                        setEligible(true)
                    } else {
                        setEligible(false)
                        setCheckRequest({
                            blown: res.data.blown,
                            pending_request: res.data.pending_request,
                            profit: res.data.profit,
                            trading_days: res.data.trading_days,
                            weeks_passed: true
                        })
                    }
                }).catch(err => {
                    console.log(err)
                    setEligible(false)
                })
            }
        }
        if (showtype.toLowerCase() === "challenge") {
            confirmVerification()
        } else {
            confirmAso()
        }
    }, [showlogin, showSca, showtype])

    const getAsoAccount = () => {
        if (showSca !== "") {

            axios.post("/requestaso/", { number: showSca }).then((res) => {
                console.log(res);
                showRequestSuccess("Your request has been sent!");
                setOpen(false)
            }).catch(err => {
                console.log(err)
                showRequestFailed("You have already made a request for an aso account, please wait 24 hours before making another.")
                setOpen(false)
            })
        } else {
            axios.post("/requestaso/", { number: showlogin }).then((res) => {
                console.log(res);
                showRequestSuccess("Your request has been sent!");
                setOpen(false)
            }).catch(err => {
                console.log(err)
                showRequestFailed("You have already made a request for an aso account, please wait 24 hours before making another.")
                setOpen(false)
            })
        }
    };

    const getVerificationAccount = () => {
        axios.post("/request-verification/", { number: showlogin }).then((res) => {
            console.log(res);
            showRequestSuccess("Your request has been sent!");
            setOpen(false)
        }).catch(err => {
            console.log(err)
            showRequestFailed("You have already made a request for an aso account, please wait 24 hours before making another.")
            setOpen(false)
        })
    }

    return (
        <div style={{ marginBottom: "88px", marginTop: "30px" }}>
            <ToastContainer />
            {showlogin === 0 || showlogin === "" ? <div className="mt-40">Your trading account is being prepared, you will have access to it soon.</div> : eligible === false ? <div className="mt-5 flex flex-col"><p>You are currently not eligible for {showtype.toLowerCase() === "challenge" ? "a Verification" : "an Aso"} account request because of the following reasons:</p>
                <ul className="mx-auto lg:w-[60%]">
                    {checkRequest.blown === false && <li className="text-left">You have blown this account or the acccount has been inactive for more than two weeks</li>}
                    {checkRequest.pending_request === false && <li className="text-left">You have made a request for an Aso account and we are currently processing your request</li>}
                    {checkRequest.profit === false && <li className="text-left">You have not met the profit target for this account</li>}
                    {checkRequest.trading_days === false && <li className="text-left">You have {showtype.toLowerCase() === "verification" && "exceeded the minimum trading weeks requirement and/or"} not met the minimum trading days requirement for this account</li>}
                    {checkRequest.weeks_passed === false && <li className="text-left">You have exceeded the minimum trading weeks requirement for this account</li>}
                </ul>
            </div> :
                <Typography sx={{ marginTop: "9%", lineHeight: "3.5" }}>
                    Click the button below to request for {showtype.toLowerCase() === "challenge" ? "a" : "an"}
                    <br />
                    {showtype.toLowerCase() === "challenge" ? "Verification account" : "Aso account"}
                </Typography>
            }
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <Box sx={mainContainer}>
                        <Typography>
                            Notice: Please note that requesting {showtype.toLowerCase() === "challenge" ? "a Verification" : "an Aso"} account implies that you are forfeiting your {showtype} account and it would be invalid.
                        </Typography>
                        <Typography>
                            Please click proceed if you accept this terms
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (showtype.toLowerCase() === "challenge") {
                                    getVerificationAccount()
                                } else {
                                    getAsoAccount()
                                }
                            }}
                            sx={{
                                marginTop: "5%",
                                width: "10%",
                                border: "none",
                                backgroundColor: "#359602",
                                color: "white",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "#359602",
                                },
                            }}
                        >
                            Proceed
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {showlogin === 0 || showlogin === "" || eligible === false ? <div></div> :
                <Button
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    sx={{
                        marginTop: "5%",
                        width: "250px",
                        border: "none",
                        backgroundColor: "#359602",
                        color: "white",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#359602",
                        },
                    }}
                >
                    Request {showtype.toLowerCase() === "challenge" ? "Verification account" : "Aso account"}
                </Button>
            }
        </div>
    );
}

export default AsoVerificationRequest;
